import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import { toast } from '../../CommonComponents/ToastComponent/toast'
import CustomTooltip from '../../CommonComponents/CustomTooltip'
import LoaderComponent from '../../CommonComponents/LoaderComponent'

import CreativeLogo from './CreativeLogo'
import CreativeHeadline from './CreativeHeadline'
import CreativeCustomImage from './CreativeCustomImage'
import CropImageModal from './CropImageModal'
import SBAdPreview from './SBAdPreview'
import SBVAdPreview from './SBVAdPreview'

import SBCreativeBrandName from '../SBCreativeBrandName'
import SBCreativeProducts from '../SBCreativeProducts'
import SBCreativeStorePages from '../SBCreativeStorePages'
import SBBrandLogoModal from '../SBBrandLogoModal'

import { createAsset } from '../../../redux/actions/campaignCreator'
import { getBase64 } from '../../../services/helper'
import {
  AD_FORMAT_COLLECTION,
  AD_FORMAT_SPOTLIGHT,
  AD_FORMAT_VIDEO,
  LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE,
  LANDING_PAGE_TYPE_STORE,
  GOAL_BRAND_IMPRESSION_SHARE,
} from '../../../utils/defaultValues'

import 'react-image-crop/dist/ReactCrop.css'

const SBCreativeSection = ({ adFormat, landingPageType, goal, basicInfo,
  products, creativeProducts, subPages, creativeSubPages,
  onChange, onProductOrderChange, onSubPageChange, onBrandLogoSelect, onCrop, isCustomImageRequired }) => {
  const inputLogoRef = useRef()
  const inputCustomRef = useRef()
  const inputVideoRef = useRef()

  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [isSaving, setIsSaving] = useState(false)

  const [logoSrc, setLogoSrc] = useState(null)
  const [croppedLogo, setCroppedLogo] = useState(null)
  const [logoImage, setLogoImage] = useState(null)

  const [customSrc, setCustomSrc] = useState(null)
  const [croppedCustomSquare, setCroppedCustomSquare] = useState(null)
  const [customImage, setCustomImage] = useState(null)
  const [customCrop, setCustomCrop] = useState(null)
  const [customSquareCrop, setCustomSquareCrop] = useState(null)


  const [openLogoModal, setOpenLogoModal] = useState(false)
  const [openCropModal, setOpenCropModal] = useState(false)

  const [cropInfo, setCropInfo] = useState({
    src: null,
    type: 'sbLogo',
    cropImage: null,
  })

  const [videoBlob, setVideoBlob] = useState(null)

  const handleBrandLogo = (seletedLogo) => {
    if (seletedLogo.width < 400 || seletedLogo.height < 400) {
      toast.show({
        title: 'Warning',
        description : 'Dimensions must be at least 400 x 400 px.',
      })
      return
    }

    onBrandLogoSelect(seletedLogo)
    setLogoSrc(seletedLogo.url)
    setCroppedLogo(seletedLogo.url)
    setOpenLogoModal(false)
  }

  const handleImageUpload = forLogo => (event) => {
    // This synthetic event is reused for performance reasons,
    // so we need to keep these values in variables.
    const files = event.target.files

    if (!files || !files.length) {
      return
    }

    if (files[0].size > 1024 * 1024 && forLogo) {
      toast.show({
        title: 'Warning',
        description: 'File size must be smaller than 1MB.',
      })
      return
    }

    if (files[0].size > 5 * 1024 * 1024 && !forLogo) {
      toast.show({
        title: 'Warning',
        description: 'File size must be smaller than 5MB.',
      })
      return
    }

    if (![
      'image/jpeg',
      'image/png',
      'image/gif',
    ].includes(files[0].type)) {
      toast.show({
        title: 'Warning',
        description: 'File format must be PNG, JPEG or GIF',
      })
      return
    }

    const reader = new FileReader()
    reader.addEventListener('load', () => {
      const img = document.createElement('img')
      img.addEventListener('load', async () => {
        if ((img.width < 400 || img.height < 400) && forLogo) {
          toast.show({
            title: 'Warning',
            description: 'Dimensions must be at least 400 x 400 px',
          })
          return
        }

        if ((img.width < 1200 || img.height < 628) && !forLogo) {
          toast.show({
            title: 'Warning',
            description: 'Dimensions must be at least 1200 x 628 px',
          })
          return
        }

        const objectUrl = URL.createObjectURL(files[0])
        if (forLogo) {
          setLogoSrc(objectUrl)
          setCroppedLogo(objectUrl)
          onCrop('sbLogo')
        } else {
          setCustomSrc(objectUrl)
          setCroppedCustomSquare(objectUrl)
          setCustomCrop(null)
          setCustomSquareCrop(null)
          // Set the default square crop ordinates,
          // because it's required by Amazon.
          onCrop('custom', null, {
            top: 0,
            left: 0,
            width: Math.min(img.width, img.height),
            height: Math.min(img.width, img.height),
          })
        }

        setIsSaving(true)
        const accessToken = await getAccessTokenSilently()
        dispatch(createAsset(accessToken, {
          assetType: 'IMAGE',
          assetSubType: 'LOGO',
          fileName: files[0].name,
          contentType: files[0].type,
          base64: reader.result,
          brandEntityId: basicInfo.brandEntityId,
          tags: ['SB'],
        })).then((response) => {
          if (forLogo) {
            onChange('logoAsset', response)
          } else {
            onChange('customImageAsset', response)
          }
        }).catch((description) => {
          toast.show({
            title: 'Danger',
            description,
          })
        }).finally(() => {
          setIsSaving(false)
        })
      })
      img.src = reader.result
    })
    reader.readAsDataURL(files[0])
  }

  const handleVideoUpload = (e) => {
    const files = e.target.files

    if (!files || !files.length) {
      return
    }

    if (files[0].type !== 'video/mp4'
      && files[0].type !== 'video/quicktime') {
      toast.show({
        title: 'Warning',
        description: 'Only mp4 or mov video file is supported.',
      })
      return
    }

    if (files[0].size > 500 * 1024 * 1024) {
      toast.show({
        title: 'Warning',
        description: 'Only less than 500 MB video file is supported.',
      })
      return
    }

    const file = files[0]
    const fileName = file.name
    const contentType = file.type

    setVideoBlob(URL.createObjectURL(file))

    setIsSaving(true)
    getBase64(file, async (result) => {
      const accessToken = await getAccessTokenSilently()
      dispatch(createAsset(accessToken, {
        assetType: 'VIDEO',
        assetSubType: 'BACKGROUND_VIDEO',
        fileName,
        contentType,
        base64: result,
        brandEntityId: basicInfo.brandEntityId,
        tags: ['SB'],
      })).then((response) => {
        onChange('videoAsset', response)
      }).catch((description) => {
        toast.show({
          title: 'Danger',
          description,
        })
      }).finally(() => {
        setIsSaving(false)
      })
    })
  }

  const handleCropModalOpen = (type) => {
    let cropSrc
    let cropImage
    if (type === 'sbLogo') {
      cropSrc = logoSrc
      cropImage = logoImage
    } else {
      cropSrc = customSrc
      cropImage = customImage
    }
    setCropInfo({
      src: cropSrc,
      type,
      cropImage,
    })
    setOpenCropModal(true)
  }

  const handleImageCrop = (croppedImage, croppingCoordinates, cropType, image) => {
    if (cropType === 'sbLogo') {
      setCroppedLogo(croppedImage)
      setLogoImage(image)
      onCrop(cropType, croppingCoordinates)
    } else if (cropType === 'custom') {
      setCustomImage(image)
      setCustomCrop(croppingCoordinates)
      onCrop(cropType, croppingCoordinates, customSquareCrop)
    } else if (cropType === 'customSquare') {
      setCroppedCustomSquare(croppedImage)
      setCustomSquareCrop(croppingCoordinates)
      onCrop(cropType, customCrop, croppingCoordinates)
    }
    setOpenCropModal(false)
  }

  const renderLogoSection = () => {
    return (
      <CreativeLogo
        adType="sb"
        inputRef={inputLogoRef}
        logoSrc={logoSrc}
        required
        onChange={onChange}
        onUpload={handleImageUpload(true)}
        onLogoSelect={() => setOpenLogoModal(true)}
        onCrop={() => { handleCropModalOpen('sbLogo') }}
      />
    )
  }

  const renderBrandNameSection = () => {
    return (
      <SBCreativeBrandName
        basicInfo={basicInfo}
        onChange={onChange}
      />
    )
  }

  const renderHeadlineSection = () => {
    return (
      <CreativeHeadline
        basicInfo={basicInfo}
        required
        onChange={onChange}
      />
    )
  }

  const renderProductsSection = () => {
    if (adFormat === AD_FORMAT_SPOTLIGHT) {
      return (
        <SBCreativeStorePages
          subPages={subPages}
          creativeSubPages={creativeSubPages}
          onChange={onSubPageChange}
        />
      )
    }

    return (
      <SBCreativeProducts
        products={products}
        creativeProducts={creativeProducts}
        onChange={onProductOrderChange}
      />
    )
  }

  const renderVideoSection = () => {
    return (
      <div className="customizer-section">
        <div className="field-section-name">
          Video
          <CustomTooltip placement="right">
            <p><strong>Video specs</strong></p>
            <ul>
              <li><strong>Aspect ratio:</strong> 16:9</li>
              <li><strong>Dimensions:</strong> 1280 x 720px, 1920 x 1080px or 3840 x 2160px</li>
              <li><strong>File size:</strong> 500 MB or smaller</li>
              <li><strong>File format:</strong> MP4 or MOV4</li>
              <li><strong>Length:</strong> 6-45 sec</li>
              <li><strong>Frame rate:</strong> 23.976, 23.98, 24, 25, 29.97, or 29.98 fps</li>
              <li><strong>Bit rate:</strong> 1 Mbps or higher</li>
              <li><strong>Codec:</strong> H.264 or H.265</li>
              <li><strong>Profile:</strong> Main or baseline</li>
              <li><strong>Video stream:</strong> 1 only</li>
            </ul>
            <p><strong>Audio specs</strong></p>
            <ul>
              <li><strong>Language:</strong> Must match ad locale</li>
              <li><strong>Sample rate:</strong> 44.1 kHz or higher</li>
              <li><strong>Codec:</strong> PCM, AAC or MP3</li>
              <li><strong>Bit rate:</strong> 96 kbps or higher</li>
              <li><strong>Format:</strong> Stereo or mono</li>
              <li><strong>Audio stream:</strong> 1 only</li>
            </ul>
            <p><strong>Text specs</strong></p>
            <ul>
              <li><strong>Font size:</strong> Must be legible on mobile</li>
              <li><strong>Language:</strong> Must match ad locale</li>
              <li><strong>Safe area:</strong> No text in lower right corner</li>
            </ul>
          </CustomTooltip>
        </div>
        <div className="field-section-contents creative-video-container">
          <input
            ref={inputVideoRef}
            type="file"
            accept="video/*"
            onChange={handleVideoUpload}
          />
          <button
            type="button"
            className="btn btn-blue"
            onClick={() => { inputVideoRef.current.value = null; inputVideoRef.current.click() }}
          >
            Upload video
          </button>
          {
            videoBlob !== null && (
              <video
                src={videoBlob}
                width="240"
                height="135"
                controls
              />
            )
          }
        </div>
      </div>
    )
  }

  const renderCustomImageSection = () => {
    if (adFormat !== AD_FORMAT_COLLECTION) {
      return null
    }

    return (
      <CreativeCustomImage
        basicInfo={basicInfo}
        inputRef={inputCustomRef}
        customSrc={customSrc}
        croppedCustomSquare={croppedCustomSquare}
        required={(goal === GOAL_BRAND_IMPRESSION_SHARE) || isCustomImageRequired}
        needSquare={false}
        onChange={onChange}
        onUpload={handleImageUpload(false)}
        onCrop={handleCropModalOpen}
      />
    )
  }

  const renderCustomizer = () => {
    if (adFormat !== AD_FORMAT_VIDEO) {
      return (
        <div className="customizer-container">
          { renderLogoSection() }
          { renderBrandNameSection() }
          { renderHeadlineSection() }
          { renderProductsSection() }
          { renderCustomImageSection() }
        </div>
      )
    }

    // For video ad format.
    if (landingPageType === LANDING_PAGE_TYPE_STORE) {
      return (
        <div className="customizer-container">
          { renderLogoSection() }
          { renderBrandNameSection() }
          { renderHeadlineSection() }
          { renderVideoSection() }
          { renderProductsSection() }
        </div>
      )
    }

    return (
      <div className="customizer-container">
        { renderVideoSection() }
      </div>
    )
  }

  const renderPreview = () => {
    if (
      (adFormat === AD_FORMAT_COLLECTION && creativeProducts.length > 0)
      ||
      (adFormat === AD_FORMAT_SPOTLIGHT && creativeSubPages.length > 0)
      ||
      (
        adFormat === AD_FORMAT_VIDEO
        && landingPageType === LANDING_PAGE_TYPE_STORE
        && videoBlob
        && creativeProducts.length
      )
    ) {
      return (
        <SBAdPreview
          adFormat={adFormat}
          basicInfo={basicInfo}
          creativeProducts={creativeProducts}
          creativeSubPages={creativeSubPages}
          croppedLogo={croppedLogo}
          videoBlob={videoBlob}
        />
      )
    }

    if (
      adFormat === AD_FORMAT_VIDEO
      && landingPageType === LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE
      && videoBlob
      && creativeProducts.length
    ) {
      return (
        <SBVAdPreview
          creativeProducts={creativeProducts}
          videoBlob={videoBlob}
        />
      )
    }
  }

  return (
    <div className={`section-container creative-section${isSaving ? ' loading' : ''}`}>
      { isSaving && <LoaderComponent /> }
      <div className="section-title">
        Creative
      </div>
      <div className="field-row">
        <div className="field-wrapper">
          { renderCustomizer() }
        </div>
        <div className="field-wrapper">
          <div className="field-name">
            Ad Preview
            <CustomTooltip placement="right">
              <p>Your ad may look slightly different than what you see
                in this preview as we continually test both new and existing features
                to determine which characteristics drive ad performance.</p>
            </CustomTooltip>
          </div>
          { renderPreview() }
        </div>
      </div>
      {
        openLogoModal && (
          <SBBrandLogoModal
            onSelect={handleBrandLogo}
            onClose={() => { setOpenLogoModal(false) }}
          />
        )
      }
      <CropImageModal
        show={openCropModal}
        cropInfo={cropInfo}
        onSelect={handleImageCrop}
        onClose={() => { setOpenCropModal(false) }}
      />
    </div>
  )
}

export default SBCreativeSection
