import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { format } from 'date-fns'

import LoaderComponent from '../CommonComponents/LoaderComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import TargetingSelector from '../CommonComponents/TargetingSelector'

import BasicInfo, { BUDGET_TYPE_DAILY, BUDGET_TYPE_LIFETIME } from './Shared/BasicInfo'
import AdgroupInfo from './Shared/AdgroupInfo'
import TargetingTypeSelector from './Shared/TargetingTypeSelector'
import SBLandingPageSection from './Shared/SBLandingPageSection'
import SBAdSection from './Shared/SBAdSection'
import SBTargetInputSection from './Shared/SBTargetInputSection'

import SBGoalSelector from './SBGoalSelector'
import SBBidSection, { BID_ADJUSTMENT_ACTION_DEC } from './SBBidSection'
import SBCreativeSection from './Shared/SBCreativeSection'

import {
  getSbSuggestions,
  getSbBidSuggestions,
  createSbCampaign,
  getSbLandingPageAsins,
  getSbStoreAsins,
} from '../../redux/actions/campaignCreator'
import { searchProduct } from '../../redux/actions/targeting'
import { selectCurrentAccount } from '../../redux/reducers/header'
import {
  compileSbAd,
  getBidLimits,
  isAsin,
  parseDate,
  parseNtExp,
  parseTargeting,
} from '../../services/helper'
import {
  validateAdgroupName,
  validateBudget,
  validateCampaignName,
  validateKeywordLength,
  validateNKLength,
  validateTargets,
} from '../../services/validator'
import {
  AD_FORMAT_COLLECTION,
  AD_FORMAT_SPOTLIGHT,
  AD_FORMAT_VIDEO,
  adFormatList,
  LANDING_PAGE_TYPE_STORE,
  LANDING_PAGE_TYPE_NEW_LANDING_PAGE,
  LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE,
  GOAL_PAGE_VISIT,
  GOAL_BRAND_IMPRESSION_SHARE,
} from '../../utils/defaultValues'

const SBCampaignCreator = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const currentAccount = useSelector(selectCurrentAccount)

  const { getAccessTokenSilently } = useAuth0()

  const [basicInfo, setBasicInfo] = useState({
    name: '',
    portfolio: null,
    dailyBudget: 10,
    budgetType: BUDGET_TYPE_DAILY,
    startDate: new Date(),
    endDate: null,
    brandEntityId: '',
    logoAsset: null,
    brandName: '',
    headline: '',
    hasCustomImage: false,
    customImageAsset: null,
    videoAsset: null,
  })

  const [goal, setGoal] = useState(GOAL_PAGE_VISIT)
  const [isCustomImageRequired, setIsCustomImageRequired] = useState(true)

  const [bidOptimization, setBidOptimization] = useState(true)
  const [bidAdjustmentAction, setBidAdjustmentAction] = useState(BID_ADJUSTMENT_ACTION_DEC)
  const [bidAdjustmentValue, setBidAdjustmentValue] = useState(30)

  const [adgroupName, setAdgroupName] = useState('Ad group 1')

  const [adFormat, setAdFormat] = useState(AD_FORMAT_COLLECTION)
  const [manualTarget, setManualTarget] = useState('keyword')

  const [landingPageType, setLandingPageType] = useState(LANDING_PAGE_TYPE_NEW_LANDING_PAGE)
  const [storePageUrl, setStorePageUrl] = useState('')

  const [products, setProducts] = useState([])
  const [keywords, setKeywords] = useState([])
  const [negativeKeywords, setNegativeKeywords] = useState([])
  const [targetings, setTargetings] = useState([])
  const [negativeTargetings, setNegativeTargetings] = useState([])

  const [creativeProducts, setCreativeProducts] = useState([])
  const [creativeSubPages, setCreativeSubPages] = useState([])
  const [logoCrop, setLogoCrop] = useState(null)
  const [customCrop, setCustomCrop] = useState(null)

  const [adName, setAdName] = useState('')

  const [isStorePageAsinsLoading, setIsStorePageAsinsLoading] = useState(false)
  const [subPages, setSubPages] = useState([])
  const [isLandingPageAsinsLoading, setIsLandingPageAsinsLoading] = useState(false)
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false)
  const [keywordSuggestions, setKeywordSuggestions] = useState([])
  const [categorySuggestions, setCategorySuggestions] = useState([])
  const [productSuggestions, setProductSuggestions] = useState([])
  const [isCreating, setIsCreating] = useState(false)

  useEffect(() => {
    if (!location.state) {
      return
    }

    if (location.state.targets && location.state.targets.length) {
      setKeywords(location.state.targets.map((target, index) => ({
        id: index,
        keywordText: target,
        matchType: 'broad',
        keywordBid: 0,
      })))

      const keywordsToGetBid = location.state.targets.map(target => ({
        keyword: target,
        matchType: 'broad',
      }));

      (async () => {
        await loadKeywordBidSuggestions(keywordsToGetBid)

        const asinTargets = location.state.targets.filter(isAsin)

        if (asinTargets.length) {
          const accessToken = await getAccessTokenSilently()
          const response = await dispatch(searchProduct(accessToken, {
            asins: asinTargets.join(),
          }))
          setTargetings(response.map(product => ({
            ...product,
            isTargeted: true,
            type: 'product',
            bid: 0,
          })))
        }
      })()
    }

    if (location.state.categories && location.state.categories.length) {
      setTargetings(location.state.categories.map(category => ({
        ...category,
        type: 'category',
        bid: 0,
      })))
    }

    if (location.state.productTargeting) {
      setManualTarget('product')
    }
  }, [location.state]) // eslint-disable-line

  const loadKeywordBidSuggestions = async (keywordsToGetBid) => {
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(getSbBidSuggestions(
      accessToken,
      keywordsToGetBid,
      adFormat,
    ))

    setKeywords(prev => prev.map((keyword) => {
      const suggestedBid = response.filter(bid => (
        bid.keyword.keywordText === keyword.keywordText
        && bid.keyword.matchType === keyword.matchType
      ))
      if (suggestedBid.length) {
        return {
          ...keyword,
          suggestedBid: suggestedBid[0].recommendedBid,
        }
      }
      return keyword
    }))
  }

  const getProductTargets = () => {
    const targets = []
    targetings.forEach((targeting) => {
      const payload = parseTargeting(targeting)
      if (!payload) {
        return
      }

      payload.expressions = payload.expression
      delete payload.expression

      targets.push(payload)
    })
    return targets
  }

  const handleBasicInfoChange = (name, value) => {
    const newInfo = Object.assign({}, basicInfo, {
      [name]: value,
    })
    setBasicInfo(newInfo)
  }

  const handleSuggestionLoad = forTarget => async () => {
    let productsToGet
    if (landingPageType === LANDING_PAGE_TYPE_STORE) {
      productsToGet = creativeProducts
    } else {
      productsToGet = products
    }

    if (productsToGet.length) {
      setIsSuggestionsLoading(true)
      const accessToken = await getAccessTokenSilently()
      const response = await dispatch(getSbSuggestions(
        accessToken,
        productsToGet.map(product => product.asin),
        forTarget,
      ))
      if (forTarget) {
        setCategorySuggestions(response.categories)
        setProductSuggestions(response.products)
      } else {
        setKeywordSuggestions(response)
      }
      setIsSuggestionsLoading(false)
    }
  }

  const handleBrandChange = (data) => {
    setBasicInfo(prev => ({
      ...prev,
      brandEntityId: data.brandEntityId,
      brandName: data.brandRegistryName,
    }))
  }

  const handleGoalChange = (value) => {
    setGoal(value)
    if (value !== GOAL_PAGE_VISIT
      && (
        landingPageType === LANDING_PAGE_TYPE_NEW_LANDING_PAGE
        || landingPageType === LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE
      )) {
      setLandingPageType(LANDING_PAGE_TYPE_STORE)
    }
  }

  const handleBrandLogoSelect = (selectedLogo) => {
    handleBasicInfoChange('logoAsset', selectedLogo)
  }

  const handleAdFormatChange = (value) => {
    setAdFormat(value)
    setProducts([])

    if (value === AD_FORMAT_SPOTLIGHT
      && (landingPageType === LANDING_PAGE_TYPE_NEW_LANDING_PAGE
        || landingPageType === LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE)) {
      setLandingPageType(LANDING_PAGE_TYPE_STORE)
    } else if (value === AD_FORMAT_VIDEO
      && landingPageType === LANDING_PAGE_TYPE_NEW_LANDING_PAGE) {
      setLandingPageType(LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE)
    } else if (value === AD_FORMAT_COLLECTION
      && landingPageType === LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE) {
      setLandingPageType(LANDING_PAGE_TYPE_NEW_LANDING_PAGE)
    }

    if (value === AD_FORMAT_COLLECTION) {
      setupCustomImageRequiredState(LANDING_PAGE_TYPE_NEW_LANDING_PAGE)
    }
  }

  const handleProductsSelect = (value) => {
    if (adFormat === AD_FORMAT_COLLECTION) {
      setProducts(value)
      setCreativeProducts(value.slice(0, 3))
    } else if (adFormat === AD_FORMAT_VIDEO) {
      if (landingPageType === LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE) {
        setProducts(value.slice(0, 1))
        setCreativeProducts(value.slice(0, 1))
      } else {
        setProducts(value.slice(0, 3))
        setCreativeProducts(value.slice(0, 3))
      }
    }
  }

  const handleKeywordsSelect = async (value, reload = false, newKeywords = []) => {
    setKeywords(value)
    if (reload && newKeywords.length) {
      await loadKeywordBidSuggestions(newKeywords)
    }
  }

  const handleImageCrop = (
    cropType,
    cropCoordinates = null,
  ) => {
    if (cropType === 'sbLogo') {
      setLogoCrop(cropCoordinates)
    } else {
      setCustomCrop(cropCoordinates)
    }
  }

  const setupCustomImageRequiredState = (value) => {
    if (adFormat === AD_FORMAT_COLLECTION && (value === LANDING_PAGE_TYPE_STORE || value === LANDING_PAGE_TYPE_NEW_LANDING_PAGE)) {
      setIsCustomImageRequired(true)
      handleBasicInfoChange('hasCustomImage', true)
    } else {
      setIsCustomImageRequired(false)

      if (!basicInfo.customImageAsset) {
        handleBasicInfoChange('hasCustomImage', false)
      }
    }
  }

  const handleLandingPageTypeChange = (value) => {
    if (
      goal === GOAL_BRAND_IMPRESSION_SHARE
      && (
        value === LANDING_PAGE_TYPE_NEW_LANDING_PAGE
        || value === LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE
      )
    ) {
      toast.show({
        title: 'Warning',
        description: 'Not available with Grow brand impression share goal.',
      })
      return
    }

    setupCustomImageRequiredState(value)
    setLandingPageType(value)
  }

  const handleStorePageChange = async (value) => {
    setStorePageUrl(value)

    setIsLandingPageAsinsLoading(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(getSbLandingPageAsins(
      accessToken,
      value,
    ))
    setIsLandingPageAsinsLoading(false)
    if (response.length < 3) {
      toast.show({
        title: 'Warning',
        description: 'This page has less than 3 products.',
      })
    } else {
      handleProductsSelect(response)
    }
  }

  const handleStoreChange = async (storePageInfo) => {
    setIsStorePageAsinsLoading(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(getSbStoreAsins(
      accessToken,
      storePageInfo,
    ))
    setIsStorePageAsinsLoading(false)
    if (response.length < 3) {
      // The response omits HOME page, so we check
      // if the count is 3, not 4.
      toast.show({
        title: 'Warning',
        description: 'The store must have 4 or more pages, each with 1 or more unique products.',
      })
    } else {
      const _creativeSubPages = []
      const _subPages = response.map((page, index) => {
        if (index < 3) {
          _creativeSubPages.push({
            id: page.pageInfo.storePageId,
            name: page.pageInfo.storePageName,
            url: page.pageInfo.storePageUrl,
            product: page.products[0],
          })
        }

        return {
          id: page.pageInfo.storePageId,
          name: page.pageInfo.storePageName,
          url: page.pageInfo.storePageUrl,
          products: page.products,
        }
      })

      setSubPages(_subPages)
      setCreativeSubPages(_creativeSubPages)

      const homeData = storePageInfo.find(page =>
        page.storePageName === 'Home'
      )
      setStorePageUrl(homeData.storePageUrl)
    }
  }

  const handleSubPageChange = (_subPages, _creativeSubPages) => {
    setSubPages(_subPages)
    setCreativeSubPages(_creativeSubPages)
  }

  const handleSave = async () => {
    const forSeller = currentAccount?.seller_type === 'seller'

    // Validate campaign name.
    let error = validateCampaignName(
      basicInfo.name,
      forSeller,
    )
    if (error) {
      toast.show({
        title: 'Warning',
        description: error,
      })
      return
    }

    // Validate budget.
    error = validateBudget(
      basicInfo.dailyBudget,
      'sb',
      currentAccount?.country_id,
      forSeller,
      basicInfo.budgetType === BUDGET_TYPE_DAILY,
    )
    if (error) {
      toast.show({
        title: 'Warning',
        description: error,
      })
      return
    }

    if (basicInfo.budgetType === BUDGET_TYPE_LIFETIME
      && !basicInfo.endDate) {
      toast.show({
        title: 'Warning',
        description: 'The lifetime budget is not available for a campaign without an end date.',
      })
      return
    }

    error = validateAdgroupName(adgroupName)
    if (error) {
      toast.show({
        title: 'Warning',
        description: error,
      })
      return
    }

    if (forSeller && !basicInfo.brandEntityId) {
      toast.show({
        title: 'Warning',
        description: 'Please select a brand.',
      })
      return
    }

    if (!adName) {
      toast.show({
        title: 'Warning',
        description: 'Please enter a ad name.',
      })
      return
    }

    if (adFormat === AD_FORMAT_COLLECTION) {
      if (landingPageType === LANDING_PAGE_TYPE_NEW_LANDING_PAGE) {
        if (products.length < 3) {
          toast.show({
            title: 'Warning',
            description: 'Please select at least 3 products.',
          })
          return
        }
      } else {
        if (!storePageUrl) {
          toast.show({
            title: 'Warning',
            description: 'Please select a store URL.',
          })
          return
        }

        if (goal === GOAL_BRAND_IMPRESSION_SHARE && !basicInfo.customImageAsset) {
          toast.show({
            title: 'Warning',
            description: 'Please select a custom image.',
          })
          return
        }
      }

      if (isCustomImageRequired && !basicInfo.customImageAsset) {
        toast.show({
          title: 'Warning',
          description: 'Please select a custom image.',
        })
        return
      }
    } else if (adFormat === AD_FORMAT_SPOTLIGHT) {
      if (!storePageUrl) {
        toast.show({
          title: 'Warning',
          description: 'Please select a store URL.',
        })
        return
      }
    } else {
      if (landingPageType === LANDING_PAGE_TYPE_STORE) {
        if (!storePageUrl) {
          toast.show({
            title: 'Warning',
            description: 'Please select a store URL.',
          })
          return
        }
      }

      if (products.length < 1) {
        toast.show({
          title: 'Warning',
          description: 'Please select at least 1 products.',
        })
        return
      }

      if (!basicInfo.videoAsset
        || !basicInfo.videoAsset.assetId) {
        toast.show({
          title: 'Warning',
          description: 'Please upload a video.',
        })
        return
      }
    }

    if (adFormat !== AD_FORMAT_VIDEO
      || landingPageType === LANDING_PAGE_TYPE_STORE) {
      if (!basicInfo.brandName) {
        toast.show({
          title: 'Warning',
          description: 'Please enter a creative brand name.',
        })
        return
      }

      if (!basicInfo.logoAsset) {
        toast.show({
          title: 'Warning',
          description: 'Please select a creative brand logo.',
        })
        return
      }

      if (!basicInfo.headline) {
        toast.show({
          title: 'Warning',
          description: 'Please enter a creative headline.',
        })
        return
      }

      let headlineLimit = 50
      if (currentAccount?.country_id === 'jp') {
        headlineLimit = 35
      }
      if (basicInfo.headline.length > headlineLimit) {
        toast.show({
          title: 'Warning',
          description: `A creative headline cannot be more than ${headlineLimit} characters.`,
        })
        return
      }
    }

    if (manualTarget === 'keyword' && keywords.length < 1) {
      toast.show({
        title: 'Warning',
        description: 'Please enter at least 1 keyword.',
      })
      return
    }
    if (manualTarget === 'product' && targetings.length < 1) {
      toast.show({
        title: 'Warning',
        description: 'Please enter at least 1 product target.',
      })
      return
    }

    if (adFormat === AD_FORMAT_SPOTLIGHT) {
      const invalidSubPage = creativeSubPages.find(subPage => (
        subPage.name.length >= 50
      ))
      if (invalidSubPage) {
        toast.show({
          title: 'Warning',
          description: 'Display name must be less than 50 characters.',
        })
        return
      }
    }

    const newCampaign = {
      campaign: {
        budgetType: basicInfo.budgetType,
        name: basicInfo.name,
        startDate: format(parseDate(basicInfo.startDate), 'yyyy-MM-dd'),
        budget: parseFloat(basicInfo.dailyBudget),
      },
      adgroup: {
        name: adgroupName,
      },
      ad: compileSbAd(
        adFormat,
        landingPageType,
        adName,
        basicInfo,
        goal,
        products,
        creativeProducts,
        creativeSubPages,
        storePageUrl,
        logoCrop,
        customCrop,
      ),
    }

    if (basicInfo.brandEntityId) {
      newCampaign.campaign.brandEntityId = basicInfo.brandEntityId
    }

    // The `goal`, `costType` and `smartDefault` parameters are
    // only available in the following marketplaces.
    if (['us', 'ca', 'gb', 'de', 'fr', 'it', 'es', 'in', 'jp'].includes(
      currentAccount?.country_id
    )) {
      newCampaign.campaign.goal = goal
      newCampaign.campaign.costType = goal === GOAL_PAGE_VISIT ? 'CPC' : 'VCPM'

      if (goal === GOAL_PAGE_VISIT && manualTarget === 'product') {
        // When the goal is selected, the smartDefault is set to TARGETING
        // to automatically create targetings when creating an ad group.
        // For the page visit goal, keyword themes will be automatically
        // created, disallowing targets and negative targets.
        // So we need to set the smartDefault to MANUAL, to add targets
        // instead of automatic keyword themes.
        newCampaign.campaign.smartDefault = ['MANUAL']
      }
    }

    if (goal === GOAL_PAGE_VISIT) {
      newCampaign.campaign.bidding = {
        bidOptimization,
      }

      if (!bidOptimization) {
        const bidPercent = (!isNaN(bidAdjustmentValue) ? parseInt(bidAdjustmentValue, 10) : 0)
          * (bidAdjustmentAction === BID_ADJUSTMENT_ACTION_DEC ? -1 : 1)

        newCampaign.campaign.bidding.bidAdjustmentsByPlacement = [
          {
            placement: 'HOME',
            percentage: bidPercent,
          },
          {
            placement: 'DETAIL_PAGE',
            percentage: bidPercent,
          },
          {
            placement: 'OTHER',
            percentage: bidPercent,
          },
        ]
      }
    }

    if (basicInfo.endDate)  {
      newCampaign.campaign.endDate = format(parseDate(basicInfo.endDate), 'yyyy-MM-dd')
    }

    if (basicInfo.portfolio) {
      newCampaign.campaign.portfolioId = parseInt(basicInfo.portfolio.portfolio_id, 10)
    }

    let adType = 'sb'
    if (adFormat === AD_FORMAT_VIDEO
      && landingPageType === LANDING_PAGE_TYPE_STORE) {
      adType = 'sbv'
    }
    const bidLimits = getBidLimits(
      adType,
      currentAccount?.country_id,
      newCampaign.campaign.costType,
    )

    let invalidError = null
    if (manualTarget === 'product') {
      newCampaign.targets = getProductTargets()
      newCampaign.negativeTargets = negativeTargetings.map(product => ({
        expressions: parseNtExp(product),
      }))
      invalidError = validateTargets(
        newCampaign.targets,
        newCampaign.campaign.budget,
        bidLimits,
      )
    } else {
      newCampaign.keywords = keywords.map(kw => ({
        keywordText: kw.keywordText !== '' ? kw.keywordText : kw.search,
        matchType: kw.matchType.toLowerCase(),
        bid: parseFloat(kw.keywordBid),
      }))
      newCampaign.negativeKeywords = negativeKeywords.map(kw => ({
        keywordText: kw.keywordText !== '' ? kw.keywordText : kw.search,
        matchType: kw.matchType,
      }))
      invalidError = validateTargets(
        newCampaign.keywords,
        newCampaign.campaign.budget,
        bidLimits,
      )
      if (!invalidError) {
        invalidError = validateKeywordLength(newCampaign.keywords)
      }
      if (!invalidError) {
        invalidError = validateNKLength(newCampaign.negativeKeywords)
      }
    }

    if (invalidError) {
      toast.show({
        title: 'Warning',
        description: invalidError,
      })
      return
    }

    setIsCreating(true)
    const accessToken = await getAccessTokenSilently()
    try {
      await dispatch(createSbCampaign(accessToken, newCampaign, adFormat))
      toast.show({
        title: 'Success',
        description: 'The campaign has been successfully created! '
          + 'Your campaign will appear in Entourage as soon as we start collecting data.',
      })
      history.push('/dashboard')
    } catch (description) {
      toast.show({
        title: 'Danger',
        description,
      })
    }
    setIsCreating(false)
  }

  const renderPageHeader = () => {
    return (
      <div className="page-header">
        <div className="page-title">Create Sponsored Brand Campaign</div>
        <button
          type="button"
          className="btn btn-red"
          onClick={handleSave}
        >
          Launch Campaign
        </button>
      </div>
    )
  }

  const renderContents = () => {
    if (!basicInfo.brandEntityId) {
      return null
    }

    if (
      (
        adFormat === AD_FORMAT_COLLECTION
        &&
        (
          (landingPageType === LANDING_PAGE_TYPE_NEW_LANDING_PAGE && products.length < 3)
          ||
          (landingPageType=== LANDING_PAGE_TYPE_STORE && !products.length)
        )
      )
      ||
      (
        adFormat === AD_FORMAT_SPOTLIGHT
        && subPages.length < 3
      )
      ||
      (
        adFormat === AD_FORMAT_VIDEO
        && !products.length
      )
    ) {
      return null
    }

    return (
      <>
        <TargetingSelector
          manualTarget={manualTarget}
          noAudience
          onChange={setManualTarget}
        />
        <SBTargetInputSection
          manualTarget={manualTarget}
          keywords={keywords}
          negativeKeywords={negativeKeywords}
          targetings={targetings}
          negativeTargetings={negativeTargetings}
          isSuggestionsLoading={isSuggestionsLoading}
          keywordSuggestions={keywordSuggestions}
          categorySuggestions={categorySuggestions}
          productSuggestions={productSuggestions}
          onFind={handleSuggestionLoad}
          onKeywordsSelect={handleKeywordsSelect}
          onNksSelect={setNegativeKeywords}
          onTargetsSelect={setTargetings}
          onNtsSelect={setNegativeTargetings}
        />
        <SBCreativeSection
          adFormat={adFormat}
          landingPageType={landingPageType}
          goal={goal}
          basicInfo={basicInfo}
          products={products}
          creativeProducts={creativeProducts}
          subPages={subPages}
          creativeSubPages={creativeSubPages}
          isCustomImageRequired={isCustomImageRequired}
          onChange={handleBasicInfoChange}
          onProductOrderChange={setCreativeProducts}
          onSubPageChange={handleSubPageChange}
          onBrandLogoSelect={handleBrandLogoSelect}
          onCrop={handleImageCrop}
        />
      </>
    )
  }

  const isLoading = isCreating
    || isLandingPageAsinsLoading
    || isStorePageAsinsLoading

  return (
    <div className={`sb-campaign-creator creator-section${isLoading ? ' loading' : ''}`}>
      { isLoading && <LoaderComponent /> }
      { renderPageHeader() }
      <div className="page-content">
        <BasicInfo
          type="sb"
          info={basicInfo}
          countryCode={currentAccount?.country_id}
          isSeller={currentAccount?.seller_type === 'seller'}
          onChange={handleBasicInfoChange}
          onBrandChange={handleBrandChange}
        />
        <SBGoalSelector
          countryCode={currentAccount?.country_id}
          goal={goal}
          onChange={handleGoalChange}
        />
        {
          goal === GOAL_PAGE_VISIT && (
            <SBBidSection
              bidOptimization={bidOptimization}
              bidAdjustmentAction={bidAdjustmentAction}
              bidAdjustmentValue={bidAdjustmentValue}
              onBidOptimizationChange={setBidOptimization}
              onBidAdjustmentActionChange={setBidAdjustmentAction}
              onBidAdjustmentValueChange={setBidAdjustmentValue}
            />
          )
        }
        <div className="section-container">
          <div className="field-row">
            <AdgroupInfo
              name={adgroupName}
              onChange={setAdgroupName}
            />
            <div className="field-wrapper">
            </div>
          </div>
        </div>
        <TargetingTypeSelector
          adType="sb"
          targetList={adFormatList}
          target={adFormat}
          onChange={handleAdFormatChange}
        />
        <SBLandingPageSection
          adFormat={adFormat}
          landingPageType={landingPageType}
          goal={goal}
          products={products}
          basicInfo={basicInfo}
          onLandingPageTypeChange={handleLandingPageTypeChange}
          onStorePageChange={handleStorePageChange}
          onStoreChange={handleStoreChange}
          onProductChange={handleProductsSelect}
        />
        <SBAdSection
          name={adName}
          onChange={setAdName}
        />
        { renderContents() }
      </div>
      <div className="page-footer">
        <button
          type="button"
          className="btn btn-red"
          onClick={handleSave}
        >
          Launch Campaign
        </button>
      </div>
    </div>
  )
}

export default SBCampaignCreator
